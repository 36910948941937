import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../../../Common/Header2';
import Footer from '../../../Common/Footer2';
import Banner from './../../../Segments/Banner';
import RelatedProjects from './../../../Segments/RelatedProjects';
import BlogComments from './../../../Segments/BlogComments';

var bnrimg = require('./../../../../images/services/pestControl/cockroach/banner.png');

const images = [
    { image: require('./../../../../images/services/pestControl/cockroach/1.png') },
    { image: require('./../../../../images/services/pestControl/cockroach/2.png') },
    { image: require('./../../../../images/services/pestControl/cockroach/3.png') },
    { image: require('./../../../../images/services/pestControl/cockroach/4.png') },
]

class CockroachTreatment extends React.Component {

    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="ANTI COCKROACH TREATMENT SERVICES." pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">Anti Cockroach Treatment Services</span></h2>
                                    </div>
                                    {/* <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div> */}
                                    <div className="wt-post-text">
                                        <p>If you are tired of using different methods for making your home or office anti cockroach, then you should now directly come to us. At SSS Facility, we are engaged in offering dependable anti cockroach treatment services to the clients at the most reasonable charges. We make use of the best quality cockroach killing chemicals for making your space anti cockroach. The aforementioned services are rendered in compliance with the set industrial standards. So, feel free to contact us anytime.</p>
                                        <p><b>Details: </b></p>
                                        <ol class="list-num-count">
                                            <li>Non - toxic and hence poses absolutely no risk to other living beings.</li>
                                            <li>It is an odourless treatment.</li>
                                            <li>Eco - Friendly in nature.</li>
                                            <li>No spray Treatment required.</li>
                                            <li>No shifting and cleaning of equipment after the treatment.</li>
                                            <li>Treatment ensures 100 % results.</li>
                                        </ol>
                                        <p><b>Methods of Application:</b></p>
                                        <p>Minute amounts of gel are applied in strategic locations with the help of syringe. Cockroaches are mostly in kitchen and pantries and we concentrate on these areas, although the gel is applied in the toilet and the entire premises. The method of applications of gel is such that it is inaccessible to infant’s pets</p>
                                        <p><b>How Gel Works:</b></p>
                                        <p>Gel has edible products especially attractive to cockroaches. Gel has cascading effect (chain effect), which results in better control over the cockroach population. Gel can be applied to sensitive area such as electrical control boxes, Kitchen appliance, computer etc. on which insecticide can not be sprayed.</p>
                                        <p><b>Spray Treatment:</b></p>
                                        <p>The Specialized service, involves rapid kill, strong flushing & residual action for all flying and crawling insects such as cockroaches, mosquitoes, flies, bed bugs, ants, moth tec. Special chemical penetrates into the deepest cracks and crevices, seeking out and destroying the insects hidden within.</p>
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../../../images/services/pestControl/cockroach/quot.png").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                    A person might be an expert in any field of knowledge or a master of many material skills and accomplishments. But without inner cleanliness his brain is a desert waste.
                                                    <div className="p-t15"><p>- Sri Sathya Sai Baba</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                <BlogComments/>
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default CockroachTreatment;