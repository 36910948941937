import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer2';
import Banner from '../Segments/Banner';

// const filters = [
//     { label: "House", filter: ".cat-1" },
//     { label: "Building", filter: ".cat-2" },
//     { label: "Office", filter: ".cat-3" },
//     { label: "Garden", filter: ".cat-4" },
//     { label: "Interior", filter: ".cat-5" }
// ];

const projects = [
    {
        image: require('./../../images/services/main-services/waterTank.png'),
        title: 'Water Tank Cleaning Services',
        description: '',
        filter: 'cat-1',
        link: "/water-tank-cleaning-services"
    },
    {
        image: require('./../../images/services/main-services/houseKeeping.png'),
        title: 'Housekeeping',
        description: '',
        filter: 'cat-2',
        link: "/housekeeping-services"
    },
    {
        image: require('./../../images/services/main-services/garden.png'),
        title: 'Garden Maintenance',
        description: "",
        filter: 'cat-3',
        link: "/garden-maintenance-services"
    },
    {
        image: require('./../../images/services/main-services/residential.png'),
        title: 'Residential Cleaning',
        description: '',
        filter: 'cat-4',
        link: "/residential-cleaning-services"
    },
    {
        image: require('./../../images/services/main-services/swimming.png'),
        title: 'Swimming Pool Maintenance',
        description: '',
        filter: 'cat-5',
        link: "/swimming-pool-maintenance-services"
    },
    {
        image: require('./../../images/services/main-services/hygiene.png'),
        title: 'Hygiene Cleaning and Support',
        description: ``,
        filter: 'cat-4',
        link: "/hygiene-cleaning-and-support-services"
    },
    {
        image: require('./../../images/services/main-services/mechanized.png'),
        title: 'Mechanized Cleaning Services',
        description: '',
        filter: 'cat-3',
        link: "/mechanized-cleaning-services"
    },
    {
        image: require('./../../images/services/main-services/structural.png'),
        title: 'Structural Cleaning Services',
        description: '',
        filter: 'cat-2',
        link: "/structural-cleaning-services"
    }
]

var bnrimg = require('./../../images/services/banner.png');

class WorkGrid extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/custom.js');
      
    };
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                    <Banner title="SSSFMS Facility Services." pagename="Work grid" bgimage={bnrimg.default} />
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 tm-work-wrap">
                    {/* <div className="container"> */}
                            {/* PAGINATION START */}
                            {/* <div className="filter-wrap p-b50">
                                <ul className="masonry-filter link-style  text-uppercase">
                                    <li className="active"><a data-filter="*" href="#">All</a></li>
                                    {filters.map((item, index) => (
                                        <li key={index}><a data-filter={item.filter} href="#">{item.label}</a></li>
                                    ))}
                                </ul>
                            </div> */}
                            {/* PAGINATION END */}
                        {/* </div> */}
                        {/* GALLERY CONTENT START */}
                        <div className="portfolio-wrap mfp-gallery work-grid clearfix">
                            <div className="container-fluid">
                                <div className="row">
                                    {projects.map((item, index) => (
                                        <div key={index} className={`${item.filter} masonry-item col-lg-3 col-md-6 col-sm-6 m-b30`}>
                                            <div className="wt-img-effect ">
                                                <img src={item.image.default} alt="" />
                                                <div className="overlay-bx-2 ">
                                                    <div className="line-amiation">
                                                        <div className="text-white font-weight-300 p-a40">
                                                            <h2><NavLink to={item.link} className="text-white font-20 letter-spacing-1 text-uppercase">{item.title}</NavLink></h2>
                                                            <p>{item.description}</p>
                                                            <NavLink to={item.link} className="v-button letter-spacing-4 font-12 text-uppercase p-l20">Read More</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* GALLERY CONTENT END */}
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>

                <Footer />
            </>
        );
    };
};

export default WorkGrid;