import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../../Common/Header2';
import Footer from '../../Common/Footer2';
import Banner from './../../Segments/Banner';
import RelatedProjects from './../../Segments/RelatedProjects';
import BlogComments from './../../Segments/BlogComments';

var bnrimg = require('./../../../images/services/houseKeeping/banner.png');

const images = [
    { image: require('./../../../images/services/houseKeeping/1.png') },
    { image: require('./../../../images/services/houseKeeping/2.png') },
    { image: require('./../../../images/services/houseKeeping/3.png') },
    { image: require('./../../../images/services/houseKeeping/4.png') },
]

class HouseKeeping extends React.Component {

    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="Housekeeping Services." pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">Housekeeping Services</span></h2>
                                    </div>
                                    {/* <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div> */}
                                    <div className="wt-post-text">
                                        <p>A well-maintained and good-looking premise also needs to be germ free. It not only furthers business prospects and creates loyal customers but also guarantees good heath & safe environment for the owner & visitors. If you are seeking professional help for completing your housekeeping requirements, get in touch with us. For us Cleanliness is next to Godliness. Keeping this in mind, SSS Facility Services started providing housekeeping services in and around Pune (Maharashtra, India). With this, we are providing prompt and precise solutions to Offices, Hospitals, and Hotels, mall’s, IT Parks, Airports, industrial, food and Pharmaceutical Units. Approach us anytime for further discussion.</p>
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../../images/services/houseKeeping/quot.png").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                Cleanliness is very important. If you let kids make a total mess in the kitchen and then leave, you're not really teaching them anything.
                                                    <div className="p-t15"><p> – Emeril Lagasse</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                <BlogComments/>
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default HouseKeeping;