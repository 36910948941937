import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../../../Common/Header2';
import Footer from '../../../Common/Footer2';
import Banner from './../../../Segments/Banner';
import RelatedProjects from './../../../Segments/RelatedProjects';
import BlogComments from './../../../Segments/BlogComments';

var bnrimg = require('./../../../../images/services/pestControl/rodent/banner.png');

const images = [
    { image: require('./../../../../images/services/pestControl/rodent/1.png') },
    { image: require('./../../../../images/services/pestControl/rodent/2.png') },
    { image: require('./../../../../images/services/pestControl/rodent/3.png') },
    { image: require('./../../../../images/services/pestControl/rodent/4.png') },
]

class RodentControl extends React.Component {

    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="RODENT CONTROL TREATMENT SERVICES." pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">Rodent Control Treatment Services</span></h2>
                                    </div>
                                    {/* <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div> */}
                                    <div className="wt-post-text">
                                        <p>Any type of rodent at any space, be it office or home, is very irritating. Moreover, it looks awkward when your guests are sitting at your home and a rat or mice pass through their legs. Thus, contact us now to control the breeding and existence of rodents at your space. We are a firm that is engaged in offering trustworthy rodent control treatment services to the clients at the most competitive charges. We make use of the most innovative techniques and rodent killing chemicals to remove any trace of rodents from your space.</p>
                                        <p><b>Damage Caused By Rodents:</b></p>
                                        <ol class="list-num-count">
                                            <li>Rodents consume and contaminate foodstuff</li>
                                            <li>Gnaw on electrical wires & wooden structures.</li>
                                            <li>Undermine building foundation and slabs with their burrowing activities.</li>
                                            <li>Damages to crops and plants.</li>
                                        </ol>
                                        <p><b>Diseases Transmit by Rodents:</b></p>
                                        <ol class="list-num-count">
                                            <li>Plague</li>
                                            <li>Typhus</li>
                                            <li>Salmonella</li>
                                        </ol>
                                        <p><b>What To Do:</b></p>
                                        <ol class="list-num-count">
                                            <li>Sanitation is fundamental to rat control and must be continuous. Good housekeeping in and around buildings reduces available shelter and food sources. Off-the-ground storage will make detection easier. Garbage, trash, and garden debris receptacles should have tight-fitting covers.</li>
                                            <li>Building Construction and Rodent Proofing is the best form of rodent control in buildings. Seal cracks in building foundations, and any openings for water pipes, electric wires, sewer pipes, drain spouts, and vents. No hole larger than 1/4 inch should be left unsealed to exclude both rats and house mice. Make sure doors, windows, and screens fit tightly. Because rats (and house mice) are excellent climbers, openings above ground level must also be plugged.</li>
                                        </ol>
                                        <p><b>Control Measures:</b></p>
                                        <ol class="list-num-count">
                                            <li>Use of sticky glue traps pre baited odorless besides being temperature resistant and non-flowing to be use in internal areas without disturbing work movement.</li>
                                            <li>Tamperproof Rodent bait stations will be used to avoid bait consumption by pest other than rodents like Cats, Dogs.</li>
                                            <li>Use of cage/snap trapping in dusty areas is also recommended</li>
                                            <li>Use of second generation anti coagulants poisons in external area to avoid entering outside rodent populations inside the premises.</li>
                                        </ol>
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../../../images/services/pestControl/rodent/quot.png").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                    Cleanliness may be defined to be the emblem of purity of mind.
                                                    <div className="p-t15"><p> – Joseph Addison</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                <BlogComments/>
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default RodentControl;