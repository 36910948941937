import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../../../Common/Header2';
import Footer from '../../../Common/Footer2';
import Banner from './../../../Segments/Banner';
import RelatedProjects from './../../../Segments/RelatedProjects';
import BlogComments from './../../../Segments/BlogComments';

var bnrimg = require('./../../../../images/services/pestControl/fly/banner.png');

const images = [
    { image: require('./../../../../images/services/pestControl/fly/4.png') },
    { image: require('./../../../../images/services/pestControl/fly/1.png') },
    { image: require('./../../../../images/services/pestControl/fly/2.png') },
    { image: require('./../../../../images/services/pestControl/fly/3.png') },
]

class FlyManagement extends React.Component {

    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="FLY MANAGEMENT SERVICES." pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">Fly Management Services</span></h2>
                                    </div>
                                    {/* <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div> */}
                                    <div className="wt-post-text">
                                        <p>Flies are one of the most irritating insects that are difficult to kill and even more difficult to control. If you are looking for some professional help for the management of flies at your space, be it office or home, then you have come to the right place. We are an organization that is engaged in offering dependable fly management services to the clients across Pune (Maharashtra, India). With the help of our best fly killing sprays and other techniques, we are capable of managing the fly breeding conveniently. So, contact us now.</p>
                                        <p><b>Fly Management Requires:</b></p>
                                        <ol class="list-num-count">
                                            <li>Good Sanitation</li>
                                            <li>Prevention of breeding</li>
                                            <li>Screening of Openings</li>
                                            <li>Air curtain - 1600 ft. / min. or more</li>
                                            <li>Insect-o-cuter</li>
                                            <li>Fly traps</li>
                                            <li>Poison baits</li>
                                            <li>Residual spraying on fly resting areas</li>
                                            <li>Space spraying or misting</li>
                                        </ol>
                                        <p><b>Note: </b>Fly Cather Machines & Air curtains are not a part of this proposal & needs to be arranged from clients end.</p>
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../../../images/services/pestControl/fly/quot.png").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                    In every aspect of life, purity and holiness, cleanliness and refinement, exalt the human condition . . . Even in the physical realm, cleanliness will conduce to spirituality.
                                                    <div className="p-t15"><p> – Abdu’ul-Baha</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                <BlogComments/>
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default FlyManagement;