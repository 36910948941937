import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../../Common/Header2';
import Footer from '../../Common/Footer2';
import Banner from './../../Segments/Banner';
import RelatedProjects from './../../Segments/RelatedProjects';
import BlogComments from './../../Segments/BlogComments';

var bnrimg = require('./../../../images/services/swimming/banner.png');

const images = [
    { image: require('./../../../images/services/swimming/1.png') },
    { image: require('./../../../images/services/swimming/2.png') },
    { image: require('./../../../images/services/swimming/3.png') },
    { image: require('./../../../images/services/swimming/4.png') },
]

class SwimmingPool extends React.Component {

    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="Swimming Pool Maintenance Services." pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">Swimming Pool Maintenance Services</span></h2>
                                    </div>
                                    {/* <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div> */}
                                    <div className="wt-post-text">
                                        <p>Appreciated for providing top-notch maintenance services, SSS Facility Services is operating from Pune (Maharashtra, India). With our expertise in this domain, we are providing maintenance services for all sorts of swimming pools. Ours pool of professionals and experts works together to provide prompt and precise solutions. Besides, we make use of new-age tools and techniques for completing the undertaken task. With us, one can stay assured for impeccable solutions complying with the set industry standards.</p>
                                        <p><b>Our standard swimming pool maintenance consists of - </b></p>
                                        <ol class="list-num-count">
                                            <li>Clean tiles - (Daily)</li>
                                            <li>Vacuum suction of pool - (M, W, F) – three days in a week</li>
                                            <li>Cleaning water (Net) - (Daily)</li>
                                            <li>Dosing necessary chemicals - (Daily)</li>
                                            <li>Checking of filtration - (Daily)</li>
                                            <li>Checking of motor - (Daily)</li>
                                            <li>Brushing of pool - (T, TH, S) – three days in a week</li>
                                            <li>Backwash filter - (M, W, F) – three days in a week</li>
                                            <li>Rinse filter - (M, W, F) – three days in a week</li>
                                            <li>Dose chlorine and necessary chemicals - (Daily)</li>
                                            <li>Cleaning of Filter room - Once in a week</li>
                                            <li>Our visit - Once in a week</li>
                                            <li>Cleaning of SS steps - (Daily)</li>
                                            <li>Removing water from filter room - Once in a week</li>
                                            <li>Testing of water PH level - Once in a week</li>
                                            <li>Testing of water chlorine level - Once in a week</li>
                                        </ol>
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../../images/services/swimming/quot.png").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                    Cleaning is my favorite way to relax. I clear things out and get rid of the stuff I don’t need. When the food pantry and the refrigerator are organized, I feel less stressed.
                                                    <div className="p-t15"><p> – Jennifer Morrison</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                <BlogComments/>
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default SwimmingPool;