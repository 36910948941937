import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../../../Common/Header2';
import Footer from '../../../Common/Footer2';
import Banner from './../../../Segments/Banner';
import RelatedProjects from './../../../Segments/RelatedProjects';
import BlogComments from './../../../Segments/BlogComments';

var bnrimg = require('./../../../../images/services/pestControl/mosquito/banner.png');

const images = [
    { image: require('./../../../../images/services/pestControl/mosquito/1.png') },
    { image: require('./../../../../images/services/pestControl/mosquito/2.png') },
    { image: require('./../../../../images/services/pestControl/mosquito/3.png') },
    { image: require('./../../../../images/services/pestControl/mosquito/4.png') },
]

class MosquitoManagement extends React.Component {

    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="MOSQUITO MANAGEMENT & CONTROL SERVICES." pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">Mosquito Management {"&"} Control Services</span></h2>
                                    </div>
                                    {/* <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div> */}
                                    <div className="wt-post-text">
                                        <p>With so many diseases spreading everywhere, it has become essential to kill the mosquitoes and stop the mosquito breeding as soon as possible. To get professional help for controlling the mosquitoes, come directly to us. At SSS Facility Services, we are engaged in offering dependable mosquito management and control services to the clients across Pune (Maharashtra, India). We make use of different types of innovative mosquito control methods to manage and control the mosquito breeding at a space. So, feel free to contact us anytime.</p>
                                        <p><b>Mosquitoes – Types / Species: </b></p>
                                        <ol class="list-num-count">
                                            <li>Anopheles spp. – prefers clean water</li>
                                            <li>Culex spp.- breeds in sewage / dirty water</li>
                                            <li>Aedes spp. – breeds in artificial containers</li>
                                            <li>Mansonia spp.-  water with hyacinths</li>
                                        </ol>
                                        <p><b>Control Measures: </b></p>
                                        <ol class="list-num-count">
                                            <li>Prevention of breeding</li>
                                            <li>Source Reduction</li>
                                            <li>Larvicidal Treatment</li>
                                            <li>Biological control</li>
                                            <li>Screening- wire / nylon mesh</li>
                                            <li>Residual spraying and Fogging</li>
                                            <li>Personal Protection</li>
                                        </ol>
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../../../images/services/pestControl/mosquito/quot.png").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                    Better keep yourself clean and bright; you are the window through which you must see the world.
                                                    <div className="p-t15"><p> -George Bernard Shaw</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                <BlogComments/>
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default MosquitoManagement;