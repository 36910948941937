import React from 'react';
import { NavLink } from 'react-router-dom';

class BlogComments extends React.Component {

  render() {

    return (
      <>
        <div className="clear" id="comment-list">
          <div className="comments-area" id="comments">
            <div className="p-t30">
              {/* COMMENT LIST END */}
              {/* LEAVE A REPLY START */}
              <div className="comment-respond bg-gray p-a30" id="respond">
                <h2 className="comment-reply-title" id="reply-title">Get In Touch
                  <small>
                    <NavLink to={""} style={{ display: 'none' }} href="#" id="cancel-comment-reply-link" rel="nofollow">Cancel reply</NavLink>
                  </small>
                </h2>
                <form className="comment-form" action="" id="commentform" method="post">
                  <p className="comment-form-author">
                    <label htmlFor="author">Name <span className="required">*</span></label>
                    <input className="form-control" type="text" defaultValue={""} name="user-comment" placeholder="Author" id="author" />
                  </p>
                  <p className="comment-form-email">
                    <label htmlFor="email">Email <span className="required">*</span></label>
                    <input className="form-control" type="text" defaultValue={""} name="email" placeholder="Email" id="email" />
                  </p>
                  <p className="comment-form-url">
                    <label htmlFor="url">Website</label>
                    <input className="form-control" type="text" defaultValue={""} name="url" placeholder="Website" id="url" />
                  </p>
                  <p className="comment-form-comment">
                    <label htmlFor="comment">Comment</label>
                    <textarea className="form-control" rows={8} name="comment" placeholder="Comment" id="comment" defaultValue={""} />
                  </p>
                  <p className="form-submit">
                    <button className="site-button black radius-no text-uppercase" type="button"><span className="font-12 letter-spacing-5">Submit</span></button>
                  </p>
                </form>
              </div>
              {/* LEAVE A REPLY END */}
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default BlogComments;