import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../../Common/Header2';
import Footer from '../../Common/Footer2';
import Banner from './../../Segments/Banner';
import RelatedProjects from './../../Segments/RelatedProjects';
import BlogComments from './../../Segments/BlogComments';

var bnrimg = require('./../../../images/services/residential/banner.png');

const images = [
    { image: require('./../../../images/services/residential/1.png') },
    { image: require('./../../../images/services/residential/2.png') },
    { image: require('./../../../images/services/residential/3.png') },
    { image: require('./../../../images/services/residential/4.png') },
]

class ResidentialCleaning extends React.Component {

    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="Residential Cleaning Services." pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">Residential Cleaning Services</span></h2>
                                    </div>
                                    {/* <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div> */}
                                    <div className="wt-post-text">
                                        <p>Get in touch with us, if seeking dependable name for competing residential cleaning requirements. SSS Facility Services is a Pune (Maharashtra, India) based company, serving clients across the national boundaries. Operating since 2016, we hold specialization in cleaning apartment, bungalow, societies, etc. We have a pool of cleaning professionals, who work as per clients’ schedule and requirements. With our services, we clean Sofa, Chair, Carpet, Mat, Tiles, etc., by using advanced equipment’s and cleaning techniques. We make use of eco-friendly cleaning material; feel free to reach us anytime for further discussion.</p>
                                       
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../../images/services/residential/quot.png").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                    Cleaning with children in the house really is like brushing your teeth while eating Oreos.
                                                    <div className="p-t15"><p> – Joleigh Little</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                <BlogComments/>
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default ResidentialCleaning;