import React from 'react';
import { NavLink } from 'react-router-dom';

class Footer2 extends React.Component {
    constructor(props) {
        super(props);
        this.currentstate = { logo: require('./../../images/logo-dark.png').default };
    }

    updateFooterLogo = (updatedlogo) => { this.currentstate.logo = updatedlogo.default; }

    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
        loadScript('./assets/js/app.js');
        loadScript('./assets/js/particles.js');
      
    };
    
    render() {

        return (
            <>
                <footer className="site-footer   footer-dark footer-wide relative overflow-hide">
                    <div className="footer-bottom overlay-wraper">
                        <div className="overlay-main bg-black" style={{ opacity: 0 }} />
                        <div className="container p-t30">
                            <div className="row">
                                <div className="col-md-4 wt-footer-bot-left">
                                
                                    <div className="widget widget_about">
                                        {/*<h4 class="widget-title">About Company</h4>*/}
                                        <div className="logo-footer clearfix p-b15">
                                        <NavLink to={"/"}><img src={require("./../../images/logo4.png").default} alt="" width={140} height={67} /></NavLink>
                                            
                                        </div>
                                        <p>Owing to immense industry experience, <strong>SSS Facility Services</strong> is involved in offering premium services such as pest management services, water tank cleaning services, housekeeping services, hygiene cleaning and support services, garden maintenance services etc.
                                        </p>
                                    </div>
                               
                                </div>
                                <div className="col-md-4 copyright-block p-t15">
                                    <div className="widget widget_services inline-block setPaddingToLinks">
                                        <h4 className="widget-title setColor">Useful links</h4>
                                        <ul className="setBottomBorderLink">
                                            <li><NavLink to={"/"} className="setLiColor">Home</NavLink></li>
                                            <li><NavLink to={"/services"} className="setLiColor">Services</NavLink></li>
                                            <li><NavLink to={"/gallery"} className="setLiColor">Gallery</NavLink></li>
                                            <li><NavLink to={"/aboutus"} className="setLiColor">About Us</NavLink></li>
                                            <li><NavLink to={"/contactus"} className="setLiColor">Contact Us</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 wt-footer-bot-right p-t15">
                                    <div className="widget widget_services inline-block">
                                        <h4 className="widget-title setColor">Quick Contact</h4>
                                        <ul className="setBottomBorder">
                                            <li><i className="fa fa-building setFAFooter"></i> SSS Facility Services</li>
                                            <li><i className="fa fa-user setFAFooter"></i> Mr. Ananta Vathore</li>
                                            <li><i className="fa fa-map-marker setFAFooter"></i> Kalpataru Serenity BLDG.No. 4A Flat No.1202 Mahadev Nagar, Mahad, Pune, Maharashtra - 411028, India</li>
                                            <li><i className="fa fa-phone setFAFooter"></i> +91-7350100718, +91-9130013861</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="row setTopBorder">
                                <div className="col-md-6 p-t15 copyrightSection">
                                    <ul className="copyrights-nav">
                                        <li>All Rights Reserved. SSS Facility Services.</li>
                                    </ul>
                                </div>
                                <div className="col-md-6 wt-footer-bot-right p-t15 developedBySection">
                                    <ul className="copyrights-nav pull-right">
                                        <li>Developed by Alotech Solutions.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="particles-dark" className="particles-canvas" />
                </footer>


            </>
        );
    };
};

export default Footer2;