import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../../Common/Header2';
import Footer from '../../Common/Footer2';
import Banner from './../../Segments/Banner';
import RelatedProjects from './../../Segments/RelatedProjects';
import BlogComments from './../../Segments/BlogComments';

var bnrimg = require('./../../../images/services/waterTank/banner.png');

const images = [
    { image: require('./../../../images/services/waterTank/1.png') },
    { image: require('./../../../images/services/waterTank/2.png') },
    { image: require('./../../../images/services/waterTank/3.png') },
    { image: require('./../../../images/services/waterTank/4.png') },
]

class WaterTank extends React.Component {

    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="Water Tank Cleaning Services." pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">Water Tank Cleaning Services</span></h2>
                                    </div>
                                    {/* <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div> */}
                                    <div className="wt-post-text">
                                        <p>SSS Facility Services is a Pune (Maharashtra, India) based company, involved in providing water tank cleaning services to the clients in the country. We have experienced and competent professionals, who work together to provide prompt cleaning solutions for the undertaken task. With our services, we make use of modern cleaning tools and techniques for completing the cleaning task with preciseness and in the provided time span. Clients can approach us anytime for further discussion.</p>
                                        <ol class="list-num-count">
                                            <li>For dewatering imported vacuum pump or high discharged monoblock pump is used as per site conditions.</li>
                                            <li>High pressure jet with variable pressure is used for cleaning Ceiling, Corners, Walls, & base of the tank.</li>
                                            <li>Vacuum sludge pump is used to dispose the dirty water & sludge from the tank.</li>
                                            <li>Specially developed antibacterial agent is spread by high pressure jet throughout water tank to remove various bacteria.</li>
                                            <li>Sludge pump is used to remove sludge after cleaning the tank. A vacuum pump is used to clean remaining dirt.</li>
                                        </ol>
                                        
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../../images/services/waterTank/quot.png").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                Literal cleanliness and orderliness can release us from abstract cognitive and affective distress - just consider how, during moments where life seems to be spiraling out of control, it can be calming to organize your clothes, clean the living room, get the car washed.
                                                    <div className="p-t15"><p>- Robert Sapolsky</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                <BlogComments/>
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default WaterTank;