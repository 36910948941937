import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import Home2 from './Pages/Home2';
import Home3 from './Pages/Home3';
import Home4 from './Pages/Home4';
import Home5 from './Pages/Home5';

//Services Pages
import WaterTank from './Pages/ServicesPages/WaterTank';

import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';

import NewsGrid from './Pages/NewsGrid';
import NewsListing from './Pages/NewsListing';
import NewsMasonary from './Pages/NewsMasonary';

import AllServices from './Pages/AllServices';
import WorkMasonary from './Pages/WorkMasonary';
import WorkCarousel from './Pages/WorkCarousel';
import ProjectDetail from './Pages/ProjectDetail';

import PostImage from './Pages/PostImage';
import PostGallery from './Pages/PostGallery';
import PostVideo from './Pages/PostVideo';
import PostRightSidebar from './Pages/PostRightSidebar';

import Accordian from './Pages/Accordian';
import AlertBox from './Pages/AlertBox';
import Button from './Pages/Button';
import Carousel from './Pages/Carousel';
import ClientLogo from './Pages/ClientLogo';
import Counter from './Pages/Counter';
import GoogleMap from './Pages/GoogleMap';
import IconBox from './Pages/IconBox';
import ListGroup from './Pages/ListGroup';
import ModalPopup from './Pages/ModalPopup';
import PricingTable from './Pages/PricingTable';
import Tabs from './Pages/Tabs';
import Table from './Pages/Table';
import Video from './Pages/Video'
import HouseKeeping from './Pages/ServicesPages/HouseKeeping';
import HygieneCleaning from './Pages/ServicesPages/HygieneCleaning';
import GardenMaintainance from './Pages/ServicesPages/GardenMaintainance';
import MechanizedCleaning from './Pages/ServicesPages/MechanizedCleaning';
import OneTimeCleaning from './Pages/ServicesPages/OneTImeCleaning';
import StructuralCleaning from './Pages/ServicesPages/StructuralCleaning';
import ResidentialCleaning from './Pages/ServicesPages/ResidentialCleaning';
import SwimmingPool from './Pages/ServicesPages/SwimmingPool';
import CockroachTreatment from './Pages/ServicesPages/PestManagmentServices/CockroachTreatment';
import DisInfestation from './Pages/ServicesPages/PestManagmentServices/DisInfestations';
import RodentControl from './Pages/ServicesPages/PestManagmentServices/RodentControl';
import MosquitoManagement from './Pages/ServicesPages/PestManagmentServices/MosquitoManagement';
import FlyManagement from './Pages/ServicesPages/PestManagmentServices/FlyManagement';

class Components extends React.Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <div className="page-wraper">
                        <Switch>
                            <Route path='/' exact component={Home} />
                            <Route path='/aboutus' exact component={AboutUs} />
                            <Route path='/contactus' exact component={ContactUs} />

                            <Route path='/water-tank-cleaning-services' exact component={WaterTank} />
                            <Route path='/housekeeping-services' exact component={HouseKeeping} />
                            <Route path='/hygiene-cleaning-and-support-services' exact component={HygieneCleaning} />
                            <Route path='/garden-maintenance-services' exact component={GardenMaintainance} />
                            <Route path='/mechanized-cleaning-services' exact component={MechanizedCleaning} />
                            <Route path='/one-time-cleaning-services' exact component={OneTimeCleaning} />
                            <Route path='/structural-cleaning-services' exact component={StructuralCleaning} />
                            <Route path='/residential-cleaning-services' exact component={ResidentialCleaning} />
                            <Route path='/swimming-pool-maintenance-services' exact component={SwimmingPool} />
                            <Route path='/anti-cockroach-treatment-services' exact component={CockroachTreatment} />
                            <Route path='/general-dis-infestations-services' exact component={DisInfestation} />
                            <Route path='/rodent-control-treatment-services' exact component={RodentControl} />
                            <Route path='/mosquito-management-and-control-services' exact component={MosquitoManagement} />
                            <Route path='/fly-management-services' exact component={FlyManagement} />

                            <Route path='/news-grid' exact component={NewsGrid} />
                            <Route path='/news-listing' exact component={NewsListing} />
                            <Route path='/news-masonary' exact component={NewsMasonary} />

                            <Route path='/services' exact component={AllServices} />
                            <Route path='/gallery' exact component={WorkMasonary} />
                            <Route path='/work-carousel' exact component={WorkCarousel} />
                            <Route path='/project-detail' exact component={ProjectDetail} />

                            <Route path='/post-image' exact component={PostImage} />
                            <Route path='/post-gallery' exact component={PostGallery} />
                            <Route path='/post-video' exact component={PostVideo} />
                            <Route path='/post-right-sidebar' exact component={PostRightSidebar} />

                            <Route path='/accordian' exact component={Accordian} />
                            <Route path='/alertbox' exact component={AlertBox} />
                            <Route path='/button' exact component={Button} />
                            <Route path='/carousel' exact component={Carousel} />
                            <Route path='/logoslider' exact component={ClientLogo} />
                            <Route path='/counter' exact component={Counter} />
                            <Route path='/googlemap' exact component={GoogleMap} />
                            <Route path='/iconbox' exact component={IconBox} />
                            <Route path='/lisgroup' exact component={ListGroup} />
                            <Route path='/modalpopup' exact component={ModalPopup} />
                            <Route path='/pricingtable' exact component={PricingTable} />
                            <Route path='/tabs' exact component={Tabs} />
                            <Route path='/table' exact component={Table} />
                            <Route path='/video' exact component={Video} />
                            
                            <Route component={Error} />
                            
                            <Route component={Error} />
                        </Switch>
                </div>
            </BrowserRouter>
        );
    };
};

export default Components;