import React from 'react';
import { NavLink } from 'react-router-dom';

class Navigation extends React.Component {

    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };

    render() {
        return (
            <>
                <ul className=" nav navbar-nav">
                    <li className="active">
                        <NavLink to={"/services"}>Services</NavLink>
                        <ul className="sub-menu v-sub-menu">
                            <li>
                                <NavLink to={"#"}>Pest Management Services</NavLink>
                                <ul className="sub-menu v-sub-menu">
                                    <li>
                                        <NavLink to={"/anti-cockroach-treatment-services"}>Anti Cockroach Treatment Services</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/general-dis-infestations-services"}>General Dis Infestations Services</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/rodent-control-treatment-services"}>Rodent Control Treatment Services</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/mosquito-management-and-control-services"}>Mosquito Management {"&"} Control Services</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/fly-management-services"}>Fly Management Services</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to={"/water-tank-cleaning-services"}>Water Tank Cleaning Services</NavLink>
                            </li>
                            <li>
                                <NavLink to={"/housekeeping-services"}>Housekeeping Services</NavLink>
                            </li>
                            <li>
                                <NavLink to={"/hygiene-cleaning-and-support-services"}>Hygiene Cleaning and Support Services</NavLink>
                            </li>
                            <li>
                                <NavLink to={"/garden-maintenance-services"}>Garden Maintenance Services</NavLink>
                            </li>
                            <li>
                                <NavLink to={"/mechanized-cleaning-services"}>Mechanized Cleaning Services</NavLink>
                            </li>
                            <li>
                                <NavLink to={"/one-time-cleaning-services"}>One-Time Cleaning Services</NavLink>
                            </li>
                            <li>
                                <NavLink to={"/structural-cleaning-services"}>Structural Cleaning Services</NavLink>
                            </li>
                            <li>
                                <NavLink to={"/residential-cleaning-services"}>Residential Cleaning Services</NavLink>
                            </li>
                            <li>
                                <NavLink to={"/swimming-pool-maintenance-services"}>Swimming Pool Maintenance Services</NavLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <NavLink to={"/gallery"}>Gallery</NavLink>
                    </li>
                    <li>
                        <NavLink to={"/aboutus"}>About Us</NavLink>
                    </li>
                    <li>
                        <NavLink to={"/contactus"}>Contact Us</NavLink>
                    </li>
                </ul>
            </>
        );
    };
};

export default Navigation;