import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const projects = [
  {
    image: require('./../../images/services/houseKeeping/1.png'),
    title: 'Housekeeping',
    description: 'Cleanliness is next to Godliness. If you are seeking the professional help for completing your housekeeping requirements, get in touch with us for more information.',
    filter: 'cat-1',
    link: '/housekeeping-services'
  },
  {
    image: require('./../../images/services/garden/1.png'),
    title: 'Garden Maintenance',
    description: "Your garden is your most beautiful masterpiece. It's a pleasure to sink our hands into the warm earth, to feel at one's fingertips the possibilities of the new season.",
    filter: 'cat-2',
    link: '/garden-maintenance-services'
  },
  {
    image: require('./../../images/services/residential/1.png'),
    title: 'Residential Cleaning',
    description: 'Let us use our magic on your home. There is always more to clean than you expect. Get the dust and dirt clean in a few seconds. Simplify your home cleaning with us.',
    filter: 'cat-3',
    link: '/residential-cleaning-services'
  },
  {
    image: require('./../../images/services/swimming/1.png'),
    title: 'Swimming Pool Maintenance',
    description: 'We treat your pool right. Spring clean your pool before the summer heat gets into full swing. Get rid of the algae in your pool with our efficient and affordable service.',
    filter: 'cat-4',
    link: '/swimming-pool-maintenance-services'
  },
  {
    image: require('./../../images/services/hygiene/1.png'),
    title: 'Hygiene Cleaning and Support',
    description: `Life is better if you are hygienic. Hygiene Innovates Your World. Giving you the best possible hygiene service is our main responsibility.`,
    filter: 'cat-5',
    link: '/hygiene-cleaning-and-support-services'
  },
  {
    image: require('./../../images/services/waterTank/4.png'),
    title: 'Water Tank Cleaning',
    description: 'Guaranteed pest removal with no hassle for you. With many years of experience in the pest control industry, we ensure that your home is safe and pest free.',
    filter: 'cat-5',
    link: '/water-tank-cleaning-services'
  }
]

class RelatedProjects extends React.Component {

  render() {
    const options = {
      loop:true,
      margin:30,
      nav:true,
      dots: false,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      responsive:{
        0:{
          items:1
        },
        480:{
          items:2
        },			
        767:{
          items:3
        },
        1000:{
          items:3
        }
      }
    };
    return (
      <>
        <div className="section-content">
          {/* TITLE START */}
          <div className="text-left">
            <h2 className="text-uppercase font-36">Other Services</h2>
            <div className="wt-separator-outer">
              <div className="wt-separator bg-black" />
            </div>
          </div>
          {/* TITLE END */}
          {/* CAROUSEL */}
          <div className="section-content">
            <OwlCarousel className="owl-carousel blog-related-slider  owl-btn-top-right" {...options}>
              {projects.map((item, index) => (
                <div className="item" key={index}>
                  <div className="blog-post blog-grid date-style-1">
                    <div className="wt-post-media wt-img-effect zoom-slow">
                      <NavLink to={item.link}><img src={item.image.default} alt="" /></NavLink>
                    </div>
                    <div className="wt-post-info p-a20 bg-gray text-black">
                      <div className="wt-post-title ">
                        <h2 className="post-title"><NavLink to={item.link} className="text-black font-16 letter-spacing-2 font-weight-600">{item.title}</NavLink></h2>
                      </div>
                      {/* <div className="wt-post-meta ">
                        <ul>
                          <li className="post-date"><strong> {item.date}</strong> </li>
                          <li className="post-author"><NavLink to={item.link}>By <span>{item.author}</span></NavLink> </li>
                        </ul>
                      </div> */}
                      <div className="wt-post-text">
                        <p>{item.description}</p>
                      </div>
                      <NavLink to={item.link} className="site-button black radius-no text-uppercase"><span className="font-12 letter-spacing-5"> Read More </span></NavLink>
                    </div>
                  </div>
                </div>

              ))}

            </OwlCarousel>
          </div>
        </div>
      </>
    );
  }
};

export default RelatedProjects;