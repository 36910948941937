import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../../../Common/Header2';
import Footer from '../../../Common/Footer2';
import Banner from './../../../Segments/Banner';
import RelatedProjects from './../../../Segments/RelatedProjects';
import BlogComments from './../../../Segments/BlogComments';

var bnrimg = require('./../../../../images/services/pestControl/infestation/banner.png');

const images = [
    { image: require('./../../../../images/services/pestControl/infestation/1.png') },
    { image: require('./../../../../images/services/pestControl/infestation/2.png') },
    { image: require('./../../../../images/services/pestControl/infestation/3.png') },
    { image: require('./../../../../images/services/pestControl/infestation/4.png') },
]

class DisInfestation extends React.Component {

    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="GENERAL DIS INFESTATIONS SERVICES." pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">General Dis Infestations Services</span></h2>
                                    </div>
                                    {/* <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div> */}
                                    <div className="wt-post-text">
                                        <p>Different types of insects triumphing at any space leave a bad impression on the guests or customers. One needs to make his/her space insect free as soon as he/she can. If you are looking for the best dis infestations solutions in Pune (Maharashtra, India), then you have come to the right place. We are an organization that is engaged in offering dependable general dis infestations services to the clients at the most reasonable charges. So, contact us now.</p>
                                        <p><b>Advantages: </b></p>
                                        <ol class="list-num-count">
                                            <li>Eco friendly inputs used</li>
                                            <li>Odourless</li>
                                            <li>Stainless</li>
                                            <li>5th Generation chemicals used for this treatment.</li>
                                            <li>Homogeneous mixture throughout the country.</li>
                                            <li>Area specific spray nozzles used to maximize area covered and hence ensure better results and longer residual effect.</li>
                                            <li>Mixture altered on frequent intervals’ to avoid immunity development for particular pesticide/ insecticide.</li>
                                            <li>All chemicals to be used are approved by Central Insecticide Board (CIB), a governing authority in the country.</li>
                                        </ol>
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../../../images/services/pestControl/infestation/quot.png").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                    Beauty commonly produces love, but cleanliness preserves it. Age itself is not unamiable while it is preserved clean and unsullied; like a piece of metal constantly kept smooth and bright, we look on it with more pleasure than on a new vessel cankered with rust.
                                                    <div className="p-t15"><p> – Joseph Addison</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                <BlogComments/>
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default DisInfestation;