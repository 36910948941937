import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../../Common/Header2';
import Footer from '../../Common/Footer2';
import Banner from './../../Segments/Banner';
import RelatedProjects from './../../Segments/RelatedProjects';
import BlogComments from './../../Segments/BlogComments';

var bnrimg = require('./../../../images/services/structural/banner.png');

const images = [
    { image: require('./../../../images/services/structural/1.png') },
    { image: require('./../../../images/services/structural/2.png') },
    { image: require('./../../../images/services/structural/3.png') },
    { image: require('./../../../images/services/structural/4.png') },
]

class StructuralCleaning extends React.Component {

    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="Structural Cleaning Services." pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">Structural Cleaning Services</span></h2>
                                    </div>
                                    {/* <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div> */}
                                    <div className="wt-post-text">
                                        <p>Hygienic Atmosphere & impressive looking are the crucial factors of any structure. Thus, it is necessary to keep it clean all the time. If you are looking for a reliable name providing structural cleaning services, you can come directly to us. SSS Facility Services is Pune (Maharashtra, India) based company, capable to undertake cleaning job of any structure of any width or height or location. The aforementioned services are rendered by a crew of qualified and experienced professionals, who make use of premium cleaning products & modern cleaning tools. For further discussion, feel free to the dial the numbers displaying on our website.</p>
                                      
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../../images/services/structural/quot.png").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                    The objective of cleaning is not just to clean, but to feel happiness living within that environment.
                                                    <div className="p-t15"><p> – Marie Kondo</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                <BlogComments/>
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default StructuralCleaning;