import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../../Common/Header2';
import Footer from '../../Common/Footer2';
import Banner from './../../Segments/Banner';
import RelatedProjects from './../../Segments/RelatedProjects';
import BlogComments from './../../Segments/BlogComments';

var bnrimg = require('./../../../images/services/garden/banner.png');

const images = [
    { image: require('./../../../images/services/garden/1.png') },
    { image: require('./../../../images/services/garden/2.png') },
    { image: require('./../../../images/services/garden/3.png') },
    { image: require('./../../../images/services/garden/4.png') },
]

class GardenMaintainance extends React.Component {

    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="Garden Maintenance Services." pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">Garden Maintenance Services</span></h2>
                                    </div>
                                    {/* <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div> */}
                                    <div className="wt-post-text">
                                        <p>SSS Facility Services is a prominent name when it comes to garden maintenance services. Operating from Pune (Maharashtra, India), we are providing top-notch maintenance solutions as per clients’ schedule, budget and requirements. We have a team of professionals, who make use of modern gardening tools and methods for rendering prompt and precise solutions.</p>
                                        <p><b>We offer garden maintenance services by - </b></p>
                                        <ol class="list-num-count">
                                            <li>Garden Landscaping & Horticulture Development</li>
                                            <li>Lawn and Garden Maintenance Services</li>
                                        </ol>
                                        <p>One can get in touch with us anytime for getting more information about us and offered services.</p>
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../../images/services/garden/quot.png").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                    A garden requires patient labor and attention. Plants do not grow merely to satisfy ambitions or to fulfill good intentions. They thrive because someone expended effort on them.
                                                    <div className="p-t15"><p> – Liberty Hyde Bailey</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                <BlogComments/>
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default GardenMaintainance;