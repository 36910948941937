import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../../Common/Header2';
import Footer from '../../Common/Footer2';
import Banner from './../../Segments/Banner';
import RelatedProjects from './../../Segments/RelatedProjects';
import BlogComments from './../../Segments/BlogComments';

var bnrimg = require('./../../../images/services/hygiene/banner.png');

const images = [
    { image: require('./../../../images/services/hygiene/1.png') },
    { image: require('./../../../images/services/hygiene/2.png') },
    { image: require('./../../../images/services/hygiene/3.png') },
    { image: require('./../../../images/services/hygiene/4.png') },
]

class HygieneCleaning extends React.Component {

    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="Hygiene Cleaning and Support Services." pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">Hygiene Cleaning and Support Services</span></h2>
                                    </div>
                                    {/* <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div> */}
                                    <div className="wt-post-text">
                                        <p>Regular cleaning is good for your heart and soul. Thus, if you are seeking professional assistance for the same, you can directly come to us. SSS Facility Services is a Pune (Maharashtra, India) based company, involved in providing cleaning and support services to commercial and residential clients. Active in this trade since 2016, we are rendering hygiene cleaning and support services in concern with clients’ requirements, schedule and budget. with us, clients can stay assured for on-time and precise solutions.</p>
                                       
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../../images/services/hygiene/quot.png").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                    Good grooming and hygiene are essential. It's never too early to start being concerned about your appearance - first impressions are everything.
                                                    <div className="p-t15"><p> – Robert Parish</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                <BlogComments/>
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default HygieneCleaning;